<template>
  <v-container fluid>
    <v-select
      v-if="cat_type < 4"
      v-model="categorieType"
      :items="[
        { id: 1, text: 'Tiers' },
        { id: 2, text: 'Produits' },
        { id: 3, text: 'Services' },
      ]"
      :item-text="'text'"
      :item-value="'id'"
      label="Type categorie"
      @change="CategorieChange(categorieType)"
    ></v-select>
    <template v-if="categorieType">
      <v-row>
        <v-col cols="12" sm="5" md="5">
          <v-treeview
            :active.sync="active"
            v-model="active"
            :items="items"
            :open.sync="open"
            :item-key="'id'"
            :item-text="'label'"
            activatable
            color="primary"
            transition
            @update:open="onOpen"
            @update:active="onActive"
            :return-object="true"
            selection-type="independent"
            :key="kt"
          >
            <template v-slot:label="{ item }">
              <v-chip :color="item.color">
                {{ item.prop_name ? item.prop_name + ":" : "" }}
                {{ item.label }}
              </v-chip>
            </template>
          </v-treeview>
        </v-col>
        <v-col cols="1" sm="1" md="1">
          <template>
            <v-btn fab small color="indigo" @click="new_categorie">
              <v-icon> mdi-plus </v-icon>
            </v-btn>
          </template>
        </v-col>
        <v-divider vertical></v-divider>
        <v-col class="pa-6" cols="5" sm="5" md="5">
          <div
            v-if="!selected"
            class="title grey--text text--lighten-1 font-weight-light"
            style="align-self: center"
          >
            no selection
          </div>
          <v-card
            v-else
            :key="selected.id"
            class="pt-6 mx-auto"
            flat
            max-width="400"
          >
            <v-card-text>
              <h3 class="blue--text headline mb-2">Libelle :</h3>
              <p class="headline mb-2">{{ selected.label }}</p>

              <div>
                <p class="blue--text mb-2">Description :</p>
                {{ selected.description }}
              </div>
              <div v-if="selected.fk_parent == 0">
                <p class="blue--text mb-2">Structure :</p>
                {{ selected.structure }}
              </div>
              <label class="blue--text subheading font-weight-bold">
                Couleur :
              </label>
              <p :style="swatchStyle" />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                @click="delete_categorie(selected)"
                :disabled="
                  selected.children.length != 0 || selected.deletable == false
                "
              >
                Supprimer
                <v-icon right> mdi-delete </v-icon>
              </v-btn>

              <v-spacer></v-spacer>

              <v-btn @click="edit_categorie(selected)">
                Modifier
                <v-icon right> mdi-pencil </v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <categorieform
      :item="editeditem"
      :list="list"
      :cat_type="cat_type"
      :structures="structures"
      :key="cf"
      :showForm="!isClosed"
      :type_cat="categorieType"
      @close="closeForm"
      :modify="true"
      @change="update"
      @add="add"
    >
    </categorieform>
    <confirmdialog ref="confirm" />
  </v-container>
</template>

<script>
function comparer(otherArray) {
  return function (current) {
    return (
      otherArray.filter(function (other) {
        return other.id == current.id;
      }).length == 0
    );
  };
}

import CATEGORIE from "../graphql/Categorie/CATEGORIE.gql";
import STRUCTURES from "../graphql/Categorie/STRUCTURES.gql";
import DELETE_CATEGORIE from "../graphql/Categorie/DELETE_CATEGORIE.gql";
function list_to_tree(list) {
  var map = {},
    node,
    roots = [],
    i;

  for (i = 0; i < list.length; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < list.length; i += 1) {
    node = list[i];
    node.index = i;
    if (node.fk_parent !== "0") {
      // if you have dangling branches check that map[node.parentId] exists
      if (list[map[node.fk_parent]])
        list[map[node.fk_parent]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}
export default {
  components: {
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    categorieform: () => import("../components/CategorieForm.vue"),
  },
  props: {
    cat_type: Number,
  },
  data() {
    return {
      kt: 100,
      cf: 0,
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      categorieType: 0,
      tabs: null,
      open: [],
      opened: [],
      opened_save: [],
      active: [],
      active_save: [],
      structures: [],
      list: [],
      items: [],
      editeditem: {},
      qDelete: {},
      isClosed: true,
      color: "",
    };
  },
  async mounted() {
    if (this.cat_type >= 4) {
      this.categorieType = this.cat_type;
      this.CategorieChange(this.categorieType);
    }
    this.qDelete = DELETE_CATEGORIE;
    let r = await this.requette(STRUCTURES);
    if (r) {
      this.structures = r.structures.filter((elm) => elm.type_id != 2);
    }
  },
  computed: {
    swatchStyle() {
      return {
        backgroundColor: this.selected.color,
        height: "30px",
        width: "30px",
        borderRadius: "4px",
        transition: "border-radius 200ms ease-in-out",
      };
    },

    selected() {
      if (!this.active.length) return undefined;
      // const id = this.active[0].id;
      return this.active[0];
    },
  },
  methods: {
    async requette(query, v) {
      let r;
      this.loading = true;
      await this.$apollo
        .query({
          query: query,
          variables: v,
          fetchPolicy: "no-cache",
        })
        .then((data) => {
          r = data.data;
          this.loading = false;
        })
        .catch((error) => {
          this.snackbar_text = error.message;
          this.snackbar_color = "error";
          this.snackbar = true;
          this.loading = false;
        });
      return r;
    },
    add(item) {
      this.list.push(item);
      this.items = list_to_tree(this.list);
      if (this.list[this.editeditem.index])
        this.list[this.editeditem.index].locked = false;
      this.kt++;
    },
    update(item) {
      this.list.splice(item.index, 1, item);
      this.items = list_to_tree(this.list);
      if (this.list[this.editeditem.index])
        this.list[this.editeditem.index].locked = false;
      this.kt++;
    },
    onActive() {
      this.active_save = this.active;
    },
    onOpen(items) {
      var onlyInA = items.filter(comparer(this.opened));
      var onlyInB = this.opened.filter(comparer(items));

      let newnode = onlyInA.concat(onlyInB);
      if (newnode.length > 0) {
        let i = items.findIndex(
          (elm) =>
            elm.id != newnode[0].id && elm.fk_parent == newnode[0].fk_parent
        );
        if (i >= 0) items.splice(i, 1);
        this.opened = [];
        this.open.forEach((element) => {
          this.opened.push(element);
          this.opened_save.push(element);
        });
        this.open = items;
      }
    },
    closeForm() {
      this.isClosed = true;
    },
    new_categorie() {
      this.cf++;
      this.editeditem = {
        id: -1,
        fk_parent: this.selected ? this.selected.id : 0,
        struct_id: this.selected ? this.selected.struct_id : null,
        color: "",
        disabled: false,
      };
      if (this.selected)
        if (!this.open.find((elm) => elm.id == this.selected.id))
          this.open.push(this.selected);
      this.isClosed = false;
    },
    edit_categorie(item) {
      this.cf++;
      this.editeditem = item;
      this.list[item.index].locked = true;
      this.isClosed = false;
    },

    async delete_categorie(item) {
      this.editeditem = item;
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Etês-vous sûr de vouloir supprimer cet element?"
        )
      ) {
        this.active = [];
        this.list.splice(item.index, 1);

        this.$apollo
          .mutate({
            mutation: this.qDelete,
            variables: {
              id: item.id,
            },
          })
          .then(() => {
            this.items = list_to_tree(this.list);
            this.kt++;
          })
          .catch((error) => {
            this.snackbar_color = "error";
            this.snackbar_text = error.message;
            this.snackbar = true;
          });
      }
    },
    async CategorieChange(type) {
      this.list = [];
      this.active = [];
      this.active_save = [];
      this.items = [];
      let r = await this.requette(CATEGORIE, { type_cat: type });
      if (r) {
        this.list = r.categorie;
        for (let i = 0; i < this.list.length; i++) {
          this.list[i].deletable = this.list[i].nbproduit == 0;
        }
        this.items = list_to_tree(this.list);
        this.open = this.opened_save;
        this.active = this.active_save;
        this.kt++;
      }
    },
  },
};
</script>
